#contacts .info
{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	padding: 40px 0;
}

#contacts .info .col h2
{
	margin: 0;
}

#contacts .info .col
{
	margin: 40px 100px;
}

.contact-link
{
	color: black;
	text-decoration: none;
}

.contact-link:hover
{
	text-decoration: underline;
}

.social-icons
{
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
	margin-top: 50px;
}

.social-icon img
{
	margin: 0 15px;
	max-width: 80px;
	max-height: 80px;
}

.current-status
{
	margin: auto;
	width: 250px;
	font-size: 0.8rem;
	border: 1px solid #ccc;
	border-radius: 15px;
}

.current-status h3, .current-status p
{
	margin: 20px 10px;
}

.map
{
	width: 100%;
	height: 500px;
	border: 0
}