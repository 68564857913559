
#brands
{
	margin: 50px 0;
	max-width: 100%;
	height: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.brand-logo
{
	margin: 50px 60px;
	height: 100%;
}

.brand-logo img
{
	max-width: 200px;
	max-height: 100px;
	height: 70%;
	transition: 200ms;
}

@media screen and (min-width: 1000px)
{
	.brand-logo img:hover
	{
		transform: scale(130%);
		transition: 500ms;
	}
}
