#header
{
	overflow: hidden;
	background-image: url("../../public/vetrina.webp");
	text-align: center;
	max-width: 100%;
	color: white;
	min-height: 600px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo
{
	padding: 20px;
}

.logo img
{
	max-height: 200px;
	margin-top: 100px;
	padding: 0;
}

.caption
{
	text-shadow: 2px 2px 4px black;
	font-size: 1.2rem;
}

@media screen and (max-width: 1000px)
{
	.logo img
	{
		max-width: 300px;
	}

	#header
	{
		min-height: 500px;
	}
}