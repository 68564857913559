nav
{
	z-index: 1;
	position: fixed;
	width: 100%;
	display: flex;
	align-items: center;
	background: #B3D9E6;
	height: 70px;
	justify-content: center;
	font-size: 0.85rem;
	box-shadow: 1px 0 10px 0 #666;
}

.nav-link
{
	font-size: 0.80rem;
	font-family: "Poppins", sans-serif;
	background: none;
	border: none;
	margin: 0 50px;
	text-decoration: none;
	text-transform: uppercase;
	color: #0080AD;
	cursor: pointer;
}

@media screen and (max-width: 1000px)
{
	.nav-link
	{
		margin: 0;
	}

	nav
	{
		justify-content: space-around;
	}
}