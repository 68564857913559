html, body
{
	font-size: 25px;
	font-family: "Poppins", sans-serif;
	margin: 0;
}

@media screen and (max-width: 1000px)
{
	html, body
	{
		font-size: 16px;
	}
}